// CLASSES
class MainView {
  #allNavs = document.querySelectorAll(".nav--list-item");
  #forms = document.querySelectorAll(".form--container");
  #menuBtn = document.querySelector(".menu--btn");
  #navList = document.querySelector(".section--nav");
  #exitMenuBtn = document.querySelector(".exit--icon-menu");
  #exitBooking = document.querySelector(".exit--booking");
  #book = document.querySelector(".book--btn");
  #bookForm = document.querySelector(".book--form");
  #concertInvite = document.querySelector(".performance--text");

  _reveal(el) {
    el.classList.remove("hidden");
  }

  _hide(el) {
    el.classList.add("hidden");
  }

  _highlight(id) {
    document.querySelector(`[data-id='${id}']`).classList.add("cur--nav");
  }

  _unhighlight() {
    this.#allNavs.forEach((nav) => {
      nav.classList.remove("cur--nav");
    });
  }

  _displayBookingForm() {
    this._reveal(this.#bookForm);
    this._closeBooking();
  }

  _closeBooking() {
    this.#exitBooking.addEventListener("click", () => {
      this._hide(this.#bookForm);
    });
  }

  book() {
    this.#book.addEventListener("click", () => {
      this._displayBookingForm();
    });
  }

  listenMenu() {
    this.#menuBtn.addEventListener("click", () => {
      this._toggleMenu();
    });

    this.#exitMenuBtn.addEventListener("click", () => {
      this._clsoeMenu();
    });
  }

  widthDetect() {
    if (window.innerWidth > 1088) return;

    if (this.#menuBtn.classList.contains("hidden")) {
      this._reveal(this.#menuBtn);
    }
  }

  _toggleMenu() {
    // for first time
    this.#navList.style.display = "inline-block";

    this.#navList.classList.toggle("hidden");
    if (this.#exitMenuBtn.classList.contains("hidden")) {
      this._reveal(this.#exitMenuBtn);
    }
  }

  _clsoeMenu() {
    this.#exitMenuBtn.classList.toggle("hidden");
    this._hide(this.#navList);
  }

  listenForm(formIcons, exitIcons, body) {
    formIcons?.forEach((icon) => {
      icon.addEventListener("click", (e) => {
        // find relative form
        const formId = e.target.dataset.id;
        // highlight current nav and disable other highlighted navs
        this._highlight(formId);

        if (!formId) return;
        // check if other forms are open and close them
        this.#forms.forEach((form) => {
          if (!form.classList.contains("hidden"))
            this._closeForm(form.querySelector(".form"), form);
          this._highlight(formId);
        });

        this._reveal(document.querySelector(`#${formId}`));
      });
    });

    exitIcons?.forEach((icon) => {
      icon.addEventListener("click", (e) => {
        // find relative form
        const secform = e.target.closest(".form--container");
        const form = secform.querySelector(".form");

        // add collapse animation to form in same time it takes to hide entire section
        this._closeForm(form, secform);
      });
    });

    body.addEventListener("click", (e) => {
      if (e.target.closest(".form")) return;
      if (e.target.closest(".nav--list-item")) return;

      document.querySelectorAll(".form").forEach((form) => {
        if (form.classList.contains("hidden")) return;
        this._closeForm(form, form.closest(".form--container"));
      });
    });
  }

  _closeForm(form, secForm) {
    this._unhighlight();

    form.classList.add("collapse");
    secForm.classList.add("fade-out");

    setTimeout(() => {
      form.classList.remove("collapse");
      secForm.classList.remove("fade-out");
      this._hide(secForm);
    }, 300);

    // check if menu is open in the smaller devices and close
    if (window.innerWidth > 1088) return;
    this._toggleMenu();
  }
}

export default new MainView();
