import MainView from "./script.js";

const init = async function () {
  try {
    const formIcons = document.querySelectorAll(".nav--list-item");
    const exitIcons = document.querySelectorAll(".exit--icon");
    const body = document.querySelector("body");

    MainView.listenForm(formIcons, exitIcons, body);
    MainView.listenMenu();
    MainView.widthDetect();
    MainView.book();
  } catch (err) {
    console.log(err);
  }
};
init();
